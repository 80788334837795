import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import db from "../db";

const Users = () => {
  const { currentUser } = useAuth();
  const [users, setUsers] = useState(null);
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    db.collection("users")
      .get()
      .then((data) => {
        const result = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(result);
        setIsPending(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const listUsers = (users) => {
    return (
      <>
        <h3 className="text-center m-4">Felhasználók</h3>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Email</th>
              <th>Jogkör</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  };

  //   useEffect(() => {
  //     if (currentUser !== null) {
  //       db.collection("users").add({
  //         email: currentUser.email,
  //         role: "user",
  //       });
  //     }
  //   }, [currentUser]);

  return (
    <>
      {isPending ? (
        <center>
          <Spinner className="mt-4" animation="border" variant="secondary" />
        </center>
      ) : (
        listUsers(users)
      )}
    </>
  );
};

export default Users;
