import Table from "react-bootstrap/Table";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";

const ReportResponse = (props) => {
  const { currentUser } = useAuth();
  const state = props.location.state;
  const data = state.reportResponse;
  const reportDate = state.reportDate;
  const drinkTypeListed = state.drinkTypeListed;
  const measureFrequency = state.measureFrequency;
  let history = useHistory();

  const reportType = () => {
    if (drinkTypeListed === "bor")
      return `Bor stand riport (${measureFrequency})`;
    else if (drinkTypeListed === "spirit")
      return `Bor stand riport (${measureFrequency})`;
  };

  return (
    <>
      <Container fluid className="p-0 mt-3">
        <Row>
          <Col md={ {span: 1, offset: 1}} xs={{span: 2, offset: 0}}>
            <Button
              className="w-20"
              variant="outline-primary"
              size="lg"
              onClick={() => history.goBack()}
            >
              ⇦
            </Button>
          </Col>
          <Col md={8} xs={10}>
            <h3 className="text-center">{reportType()}</h3>
          </Col>
          <Col md={2}></Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center">
              Dátum: {reportDate} | Beküldő: {currentUser.email} | Küldések
              száma: 1
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={12} md={10}>
            <Table
              className="responsive-table-text"
              striped
              bordered
              hover
              size="sm"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Megnevezés</th>
                  <th>Nyitó</th>
                  <th>Eladás(L)</th>
                  <th>Bevét(L)</th>
                  <th>Záró</th>
                  <th>Eltérés(L)</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((drink, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{drink.Termék}</td>
                      <td>{drink.Nyitó}</td>
                      <td>{drink.Eladás}</td>
                      <td>{drink.Bevételezés}</td>
                      <td>{drink.Záró}</td>
                      <td>{drink.Eltérés}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReportResponse;
