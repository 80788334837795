import { Container, Row, Col, Tabs, Tab, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import MeasuringTable from "./MeasuringTable";
import CurrentDrinkLoaded from "./CurrentDrinkLoaded";
import Scale from "./Scale";
import SendReport from "./SendReport";
import db from "../db";

const Measuring = () => {
  const [drinks, setDrinks] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [currentDrink, setCurrentDrink] = useState(null);
  const [currentMeasure, setCurrentMeasure] = useState(null);
  const [isScaleConnected, setIsScaleConnected] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [drinkTypeListed, setDrinkTypeListed] = useState(null);
  const [measureFrequency, setMeasureFrequency] = useState("napi");
  let match = useRouteMatch();

  useEffect(() => {
    let query = null;

    const fetchDrinks = (query) => {
      query
        .get()
        .then((data) => {
          const result = data.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDrinks(result);
          setIsPending(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    // currently if measureFrequency is "heti", it should contain all the drinks, even "napi"
    if (match.url === "/measure-wine") {
      if (measureFrequency === "napi") {
        query = db
          .collection("restaurant01")
          .where("category", "==", "bor")
          .where("measureFrequency", "==", `${measureFrequency}`)
          .orderBy("ordering");
      } else if (measureFrequency === "heti") {
        query = db
          .collection("restaurant01")
          .where("category", "==", "bor")
          .orderBy("ordering");
      }
      setDrinkTypeListed("bor");
    } else if (match.url === "/measure-spirit") {
      if (measureFrequency === "napi") {
        query = db
          .collection("restaurant01")
          .where("category", "!=", "bor")
          .where("measureFrequency", "==", `${measureFrequency}`)
          .orderBy("category")
          .orderBy("ordering");
      } else if (measureFrequency === "heti") {
        query = db
          .collection("restaurant01")
          .where("category", "!=", "bor")
          .orderBy("category")
          .orderBy("ordering");
      }
      setDrinkTypeListed("spirit");
    }
    fetchDrinks(query);
  }, [isSubmitted, match.url, measureFrequency]);

  return (
    <>
      {isPending ? (
        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: 100 }}
        >
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <Container>
          <Row>
            <Col xs={12} md={5}>
              <Row>
                <Col>
              <div
                className="mr-4 w-100"
                style={{
                  border: "2px solid black",
                  borderRadius: 10,
                  height: 400
                }}
              >
                <div className="d-flex align-items-center flex-column mt-4">
                  {isScaleConnected && (
                    <CurrentDrinkLoaded
                      drink={currentDrink}
                      currentMeasure={currentMeasure}
                      setIsSubmitted={setIsSubmitted}
                    />
                  )}
                </div>
                <div className="p-4">
                  <Scale
                    setCurrentMeasure={setCurrentMeasure}
                    setIsScaleConnected={setIsScaleConnected}
                  />
                </div>
              </div>
              </Col>
              </Row>
                <Row>
                  <Col className="pt-2">
                    <SendReport
                      drinks={drinks}
                      drinkTypeListed={drinkTypeListed}
                      measureFrequency={measureFrequency}
                    />
                  </Col>
                </Row>
            </Col>
            <Col xs={12} md={7} className="p-0">
                <Row>
                  <Col>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={measureFrequency}
                      onSelect={(k) => setMeasureFrequency(k)}
                      transition={false} //check if enabled still creates an error with findDOMNode
                    >
                      <Tab eventKey="napi" title="Napi stand">
                        <MeasuringTable
                          drinks={drinks}
                          setCurrentDrink={setCurrentDrink}
                        />
                      </Tab>
                      <Tab eventKey="heti" title="Heti stand">
                        <MeasuringTable
                          drinks={drinks}
                          setCurrentDrink={setCurrentDrink}
                        />
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Measuring;
