import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Alert } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
// import { Image } from "react-bootstrap";
// import Logo from "../assets/team-deryne.png";

const Navigation = () => {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("A kijelentkezés nem sikerült!");
    }
  }

  return (
    <>
      {currentUser && (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Link to="/" style={{ width: "10%" }}>
            <Navbar.Brand style={{ padding: 0 }}>
              {/* <Image src={Logo} style={{ width: 100 }}></Image> LOGO TEMPORARILY TURNED OFF*/}
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <NavDropdown title="Standolás" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/measure-wine">
                Bor stand
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/measure-spirit">
                Spirit stand
              </NavDropdown.Item>
            </NavDropdown>
            {(currentUser.email === "attila.beli@protonmail.com" || "tamas@deryne.com") && <Nav.Link as={Link} to="/stock">
              Készlet
            </Nav.Link>}
            <Nav.Link as={Link} to="/add">
              Új termék
            </Nav.Link>
            {error && <Alert variant="danger">{error}</Alert>}
            <Nav.Link as={Link} to="/profile" className="nav-link">
              Profilom
            </Nav.Link>
            {(currentUser.email === "attila.beli@protonmail.com" || "tamas@deryne.com") && <NavDropdown title="Admin Panel" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/admin">
                Felhasználók
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/logs">
                Tevékenységnapló
              </NavDropdown.Item>
            </NavDropdown>}
            <Nav.Link to="" onClick={handleLogout} className="nav-link">
              Kijelentkezés
            </Nav.Link>
          </Navbar.Collapse>
        </Navbar>
      )}
    </>
  );
};

export default Navigation;
