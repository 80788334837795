import getDateYYYYMMDD from './getDateYYYMMDD';
import { subDays } from 'date-fns'

// if the time of measure is between 00.00 and 20.00, decrement day YYYYMMDD value by 1
const getDateOfMeasure = () => {
    const today = new Date();
    const actualHour = today.getHours();
    if (actualHour >= 0 && actualHour < 20) {
      // let testZeros = (num) => num % 100 === 0
      return getDateYYYYMMDD(subDays(today, 1));
    } else {
      return getDateYYYYMMDD(today);
    }
  };

export default getDateOfMeasure