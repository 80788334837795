import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";

const MessageToast = (props) => {
  const { show, setShow, headerText, message } = props;

  return (
    <div
      style={{
        width: "100%",
        position: "fixed",
        top: 80,
        left: "45%",
        zIndex: 2,
      }}
    >
      <Row>
        <Col xs={6}>
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={4000}
            autohide
            animation={false}
          >
            <Toast.Header closeButton={false}>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">{headerText}</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        </Col>
      </Row>
    </div>
  );
};

export default MessageToast;
