import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import db from "../db";
import Table from "react-bootstrap/Table";
import getActualAmountFromWeight from "../utils/getActualVolumeFromWeight";
import axios from "axios";

const Main = () => {
  const [drinks, setDrinks] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const history = useHistory();

  const [productList, setProductList] = useState(null)

  useEffect(() => {
    db.collection("restaurant01")
      .orderBy("category")
      .orderBy("name")
      .get()
      .then((data) => {
        const result = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setDrinks(result);
        setIsPending(false);
      })
      .catch((error) => {
        console.error(error);
      });
      getProductsFromBackend();
  }, []);

  useEffect(() => {
    console.log(productList)
  }, [productList]);

  const handleItemClick = (id) => {
    history.push(`/stock/${id}`);
  }

  const getProductsFromBackend = () => {

    const baseURL = "http://standpolizei.tetel.me/sendProducts";
    const data = ""

    var config = {
      method: 'post',
      url: `https://infinite-savannah-13185.herokuapp.com/${baseURL}`,
      headers: { 
        PolizeiToken: process.env.REACT_APP_REPORT_TOKEN,
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      setProductList(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

    // const data = ""

    // const baseURL = "http://standpolizei.tetel.me/sendProducts";

    // axios
    //   .post(`https://infinite-savannah-13185.herokuapp.com/${baseURL}`, data, { PolizeiToken: "&#kWe5pcF=uA^62c" })
    //   .then((response) => console.log(response.data))
    //   .catch((error) => {
    //     console.error("There was an error!", error);
    //   });
  }

  return (
    <>
      {/* <div className="d-flex justify-content-end">
        <Button
          className="m-2"
          variant="outline-primary"
          size="sm"
          onClick={handleClickCSV}
        >
          Letöltés CSV-ben
        </Button>
        <Button
          className="m-2"
          variant="outline-primary"
          size="sm"
          onClick={handleClickReport}
          disabled={isReportPending}
        >
          Riport küldése
        </Button>
      </div> */}
      {!isPending && (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Megnevezés</th>
              <th>Kategória</th>
              <th>Mennyiség (liter)</th>
              <th>Beszállító</th>
            </tr>
          </thead>
          <tbody>
            {drinks.map((drink, i) => (
              <tr style={{cursor: 'pointer'}} key={drink.id} onClick={(id) => handleItemClick(drink.id)}>
                <td>{i + 1}</td>
                <td>{drink.name}</td>
                <td>{drink.category}</td>
                <td>{getActualAmountFromWeight(drink)}</td>
                <td>{drink.supplierID}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Main;
