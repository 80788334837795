import Users from "./Users"

const Admin = () => {
    return ( 
        <>
        <Users />
        </>
     );
}
 
export default Admin;