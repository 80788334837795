import { Button } from "react-bootstrap";
// import getTodaysMeasure from "../utils/getTodaysMeasure";
import getDateOfMeasure from "../utils/getDateOfMeasure";
import amountInLiter from "../utils/amountInLiter";
import db from "../db";
import { useState } from "react";

const CurrentDrinkLoaded = (props) => {
  const drink = props.drink;
  const currentMeasure = props.currentMeasure;
  const [numberOfFullBottles, setNumberOfFullBottles] = useState(0);


  const Counter = () => {

    const decrease = () => {
      if (numberOfFullBottles <= 0) {
        return;
      } else {
        setNumberOfFullBottles(numberOfFullBottles - 1);
      }
    };
  
    return (
      <div style={{width: 300, display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
        <Button type="primary" onClick={decrease}> - </Button>
        <div style={{fontSize: 40}}>{numberOfFullBottles}</div>
        <Button type="primary" onClick={() => setNumberOfFullBottles(numberOfFullBottles + 1)}> + </Button>
        <Button type="primary" onClick={() => setNumberOfFullBottles(numberOfFullBottles + 10)}> +10 </Button>
      </div>
    );
  }


  // currently this goes to negative until it reaches the empty bottles weight
  // need to get the stabilized byte and hide the result before stopping on a stabilized value
  const totalAmountInGrams = () => {
    const netWeight = drink.weightFull - drink.weightEmpty;
    const fullBottlesWeight = numberOfFullBottles * netWeight;
    const totalAmount = fullBottlesWeight + currentMeasure;
    if (currentMeasure === 0 || currentMeasure === null) {
      return fullBottlesWeight;
    } else {
      return totalAmount - drink.weightEmpty;
    }
  };

  const handleSaveClick = () => {
    if (totalAmountInGrams() <= 0) {
      alert("A mennyiség nem lehet nulla vagy negatív!")
    } else {
      //pre-calcualate key based on today's date
      const keyToUpdate = `history.${getDateOfMeasure()}`;
      db.collection("restaurant01")
        .doc(`${drink.id}`)
        .update({
          [keyToUpdate]: `${totalAmountInGrams()}`,
        })
        .then(() => {
          console.log("Document successfully updated!");
          props.setIsSubmitted(true);
          setNumberOfFullBottles(0);
        });
    }
  };

  props.setIsSubmitted(false);

  return (
    <>
      {!drink && <p>Válassz ki egy italt a kezdéshez!</p>}
      {drink && (
        <>
          <div className="currentMeasureDisplay">
            <strong>{amountInLiter(totalAmountInGrams(), drink)} </strong>
            <small>liter</small>
          </div>
          <p>
            <strong className="currentMeasureDrinkName">{drink.name}</strong>
          </p>
          <p>
            Termék kategóriája: <strong>{drink.category}</strong>
          </p>
          <div style={{ textAlign: "center" }}>Bontantlan üvegek száma</div>
          <Counter />
          <Button className="mt-3" variant="success" onClick={handleSaveClick}>
            Mentés
          </Button>
        </>
      )}
    </>
  );
};

export default CurrentDrinkLoaded;
