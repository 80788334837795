import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import getDateOfMeasure from "../utils/getDateOfMeasure";
import getTodaysMeasure from "../utils/getTodaysMeasure";
import amountInLiter from "../utils/amountInLiter";
import { useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../contexts/AuthContext";
import MessageToast from "./MessageToast";
import { useHistory } from "react-router-dom";

const SendReport = (props) => {
  let history = useHistory();
  const drinks = props.drinks;
  const drinkTypeListed = props.drinkTypeListed;
  const measureFrequency = props.measureFrequency;
  const [isReportPending, setIsReportPending] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toastHeaderText = "Riport";
  const { currentUser } = useAuth();

  const handleResponse = (response) => {
    const data = response.data;
    //redirect to /reportresponse to display complete report received from backend
    history.push({
      pathname: "/reportresponse",
      state: {
        reportResponse: data,
        reportDate: getDateOfMeasure(),
        drinkTypeListed: drinkTypeListed,
        measureFrequency: measureFrequency,
      },
    });
  };

  const handleSendReport = () => {
    setIsReportPending(true);
    const baseURL = process.env.REACT_APP_REPORT_BASEURL;

    const data = {
      reportId: uuidv4(),
      reporter: currentUser.email,
      date: getDateOfMeasure(),
      drinkTypeListed: drinkTypeListed,
      measureFrequency: measureFrequency,
      drinks: [],
    };

    // add converted amount of drinks to data
    drinks.forEach((drink, i) => {
      const todaysAmount = (drink) => {
        return amountInLiter(getTodaysMeasure(drink), drink);
      };

      const newRow = {
        id: drink.id,
        name: drink.name,
        amount: todaysAmount(drink),
      };
      data.drinks.push(newRow);
    });

    const headers = {
      PolizeiToken: process.env.REACT_APP_REPORT_TOKEN,
    };

    axios
      .post(`${baseURL}/polizei/standHandler`, data, {
        headers,
      })
      .then((response) => handleResponse(response))
      // .then(() => {
      //   setIsReportPending(false);
      //   setToastMessage("SIKER! Riport beküldve!");
      //   setShowToast(true);
      // })
      .catch((error) => {
        setIsReportPending(false);
        setToastMessage("HIBA! A Beküldés sikertelen!");
        setShowToast(true);
        console.error("There was an error!", error);
      });
  };

  return (
    <>
      <Button
        className="w-100"
        variant="outline-primary"
        size="sm"
        onClick={handleSendReport}
        disabled={isReportPending}
      >
        {isReportPending ? (
          <span>
            <Spinner animation="border" size="sm" /> Küldés folyamatban
          </span>
        ) : (
          <span>Riport küldése</span>
        )}
      </Button>
      <MessageToast
        show={showToast}
        setShow={setShowToast}
        message={toastMessage}
        headerText={toastHeaderText}
      />
    </>
  );
};

export default SendReport;
