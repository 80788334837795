  //generate yesterdays date in YYYMMDD format to be used as object key for drink history

  const getDateYYYYMMDD = (input) => {
    let date = input;
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = date.getFullYear();
    date = parseInt(yyyy + mm + dd);
    return date;
  };
  
  export default getDateYYYYMMDD;