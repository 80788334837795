import { useRouteMatch } from "react-router";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import db from "../db";

const EditItem = () => {
  let match = useRouteMatch();
  const history = useHistory();
  const [drink, setDrink] = useState("");
  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    const unsubscribe = db
      .collection("restaurant01")
      .doc(`${match.params.id}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setDrink(doc.data());
        } else {
          console.log("No such document!");
        }
        setIsPending(false);
      });
    return () => {
      unsubscribe();
    };
  }, [match.params.id]);

  return (
    <>
      {!isPending && (
        <Formik
          initialValues={{
            name: drink.name,
            category: drink.category,
            volume: drink.volume,
            unit: drink.unit,
            supplierID: drink.supplierID,
            weightEmpty: drink.weightEmpty,
            weightFull: drink.weightFull,
            measureFrequency: drink.measureFrequency,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Add meg a termék nevét!"),
            category: Yup.string().required(
              "Válaszd ki a termék kategóriáját!"
            ),
            volume: Yup.string().required("Add meg az ital űrtartalmát!"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            db.collection("restaurant01")
              .doc(`${match.params.id}`)
              .update(values)
              .then(() => {
                alert("A termék frissítve!");
                history.push("/stock");
              })
              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });

            setSubmitting(false);
          }}
        >
          <Container style={{ paddingTop: 20 }}>
            <Form>
              <Row>
                <Col>
                  <label style={{ paddingRight: 10 }} htmlFor="name">
                    Megnevezés
                  </label>
                  <Field style={{ width: 400 }} name="name" type="text" />
                  <ErrorMessage name="name" />
                </Col>
                <Col>
                  <label style={{ paddingRight: 10 }} htmlFor="category">
                    Kategória
                  </label>
                  <Field name="category" as="select">
                    <option value="">Válassz...</option>
                    <option value="bor">bor</option>
                    <option value="whiskey">whiskey</option>
                    <option value="gin">gin</option>
                    <option value="rum">rum</option>
                    <option value="digestif">digestif</option>
                    <option value="pezsgő">pezsgő</option>
                    <option value="champagne">champagne</option>
                    <option value="tequila">tequila</option>
                    <option value="konyak">konyak</option>
                    <option value="aperitif">aperitif</option>
                    <option value="vodka">vodka</option>
                    <option value="sör">sör</option>
                  </Field>
                  <ErrorMessage name="category" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label style={{ paddingRight: 10 }} htmlFor="volume">
                    Űrtartalom (liter)
                  </label>
                  <Field name="volume" type="text" />
                  <ErrorMessage name="volume" />
                </Col>
                <Col>
                  <label style={{ paddingRight: 10 }} htmlFor="unit">
                    Csomagolási egység
                  </label>
                  <Field name="unit" as="select">
                    <option value="üveg">üveg</option>
                  </Field>
                  <ErrorMessage name="unit" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label style={{ paddingRight: 10 }} htmlFor="weightFull">
                    Bontatlan üveg súlya (gramm)
                  </label>
                  <Field name="weightFull" type="text" />
                  <ErrorMessage name="weightFull" />
                </Col>
                <Col>
                  <label style={{ paddingRight: 10 }} htmlFor="weightEmpty">
                    Üres üveg súlya (gramm)
                  </label>
                  <Field name="weightEmpty" type="text" />
                  <ErrorMessage name="weightEmpty" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label
                    style={{ paddingRight: 10 }}
                    htmlFor="measureFrequency"
                  >
                    Standolás gyakorisága
                  </label>
                  <Field name="measureFrequency" as="select">
                    <option value="">Válassz...</option>
                    <option value="heti">heti</option>
                    <option value="napi">napi</option>
                  </Field>
                  <ErrorMessage name="measureFrequency" />
                </Col>
              </Row>
              <Button type="submit">Frissítés</Button>
            </Form>
          </Container>
        </Formik>
      )}
    </>
  );
};

export default EditItem;
